import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AppLayout } from '../../Layouts'
import Users from './Users/Users'
import User from './Users/User'
import Coupons from './Coupons/Coupons'
import Coupon from './Coupons/Coupon'
import Notifications from './Notifications/Notifications'
import Notification from './Notifications/Notification'
import CreateNotification from './Notifications/CreateNotification'
import Items from './Items/Items'
import Item from './Items/Item'
import CreateItem from './Items/CreateItem'
import Polls from './Polls/Polls'
import Poll from './Polls/Poll'
import CreatePoll from './Polls/CreatePoll'
import InviteUser from './Users/InviteUser'
import Feedbacks from './Feedback/Feedbacks'
import Analytics from './Analytics/Analytics'
import Settings from './Settings/Settings'
import EmailChange from './EmailChange/EmailChange'
import CreateCoupon from './Coupons/CreateCoupon'
import CreateCouponOrganization from './Coupons/CreateCouponOrganization'
import CreateCouponCategory from './Coupons/CreateCouponCategory'
import CouponOrganization from './Coupons/CouponOrganization'
import CouponCategory from './Coupons/CouponCategory'
import CityFeedback from './Feedback/CityFeedback'
import FaultReport from './Feedback/FaultReport'

export default function Authorized() {
  return (
    <AppLayout>
      <Switch>
        <Route exact path='/users' component={Users} />
        <Route exact path='/coupons' component={Coupons} />
        <Route exact path='/coupons/add' component={CreateCoupon} />
        <Route exact path='/coupon-notifications/add' render={(props) => (<CreateNotification {...props} type='coupon' />)} />
        <Route exact path='/coupon-notifications/:notificationId' render={(props) => (<Notification {...props} type='coupon' />)} />
        <Route exact path='/coupons/organization/:organizationId' component={CouponOrganization} />
        <Route exact path='/coupons/add-organization' component={CreateCouponOrganization} />
        <Route exact path='/coupons/category/:categoryId' component={CouponCategory} />
        <Route exact path='/coupons/add-category' component={CreateCouponCategory} />
        <Route exact path='/coupons/:couponId' component={Coupon} />
        <Route exact path='/notifications' component={Notifications} />
        <Route exact path='/notifications/add' component={CreateNotification} />
        <Route exact path='/notifications/:notificationId' component={Notification} />
        <Route exact path='/items' component={Items} />
        <Route exact path='/items/add' component={CreateItem} />
        <Route exact path='/items/:itemId' component={Item} />
        <Route exact path='/polls' component={Polls} />
        <Route exact path='/polls/add' component={CreatePoll} />
        <Route exact path='/polls/:pollId' component={Poll} />
        <Route exact path='/users/invite' component={InviteUser} />
        <Route exact path='/users/:userId' component={User} />
        <Route exact path='/feedbacks' component={Feedbacks} />
        <Route exact path='/feedbacks/city-feedback/:cityFeedbackId' component={CityFeedback} />
        <Route exact path='/feedbacks/fault-report/:faultReportId' component={FaultReport} />
        <Route exact path='/analytics' component={Analytics} />
        <Route exact path='/settings' component={Settings} />
        <Route exact path='/email-change' component={EmailChange} />
        <Redirect from='*' to='/settings' />
      </Switch>
    </AppLayout>
  )
}
