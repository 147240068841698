import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import get from 'lodash/get'
import { Button, Title, Input, LangTabs, EditorInput, Select, Dialog, PageContainer, PageContent, PageActions } from '../'
import { Colors } from '../../Utils/theme'
import { ITEM_TYPES } from '../../Constants'
import MediaInput from '../../Containers/Authorized/Notifications/MediaInput'
import { GOOGLE_MAPS_API_KEY } from '../../Constants'

// For html stuff
import * as striptags from 'striptags'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import linkifyHtml from 'linkify-html'
import { decode } from 'html-entities'


const MAP_HEIGHT = 400
const MAP_WIDTH = 600

const useStyles = makeStyles((theme) =>({
  container: {
    maxWidth: 700
  },
  spacer: {
    width: 32
  },
  coordsRow: {
    maxWidth: '30rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  coordsButton: {
    marginBottom: '1.25rem',
    marginLeft: '1.5rem'
  },
  mapsPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: Colors.cardBackgroundColor,
    color: Colors.content,
    height: MAP_HEIGHT,
    width: MAP_WIDTH,
    marginBottom: '1.5rem'
  },
  mapContainer: {
    marginBottom: '1.5rem'
  }
}))

function DynamicItemForm (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  // Current config by type
  const config = ITEM_TYPES.find(it => it.type === props.type)

  const [langTab, setLangTab] = useState(get(config.locales, '[0]', null))

  const [titleFi, setTitleFi] = useState(null)
  const [titleEn, setTitleEn] = useState(null)
  const [contentFi, setContentFi] = useState(null)
  const [contentEn, setContentEn] = useState(null)

  const [category, setCategory] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [price, setPrice] = useState(null)
  const [organization, setOrganization] = useState(null)
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [link, setLink] = useState(null)
  const [phone, setPhone] = useState(null)
  const [address, setAddress] = useState(null)
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)

  const [photo, setPhoto] = useState(null)
  const [newPhoto, setNewPhoto] = useState(null)
  const [removePhoto, setRemovePhoto] = useState(false)
  const [video, setVideo] = useState(null)
  const [mediaType, setMediaType] = useState(null)

  const [alertVisible, setAlertVisible] = useState(false)
  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)

  const toggleDelete = () => {
    props.toggleActive()
    closeAlert()
  }

  const renderDelete = () => {
    if (props.mode === 'edit' && props.item) {
      return (
        <div>
          <div className={classes.line}></div>
          <Button
            text={props.item.active ? t('delete_item') : t('activate_item')}
            onClick={openAlert}
            secondary
            margin
            fullWidth
          />
        </div>
      )
    }
    return null
  }

  useEffect(() => {
    if (props.item) {
      // Basic contents
      setTitleFi(get(props.item, 'titleFi', null))
      setTitleEn(get(props.item, 'titleEn', null))
      const newContentFi = get(props.item, 'htmlFi') || get(props.item, 'contentFi') || ''
      if (newContentFi) {
        const { contentBlocks, entityMap } = htmlToDraft(newContentFi)
        setContentFi(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
      }
      const newContentEn = get(props.item, 'htmlEn') || get(props.item, 'contentEn') || ''
      if (newContentEn) {
        const { contentBlocks, entityMap } = htmlToDraft(newContentEn)
        setContentEn(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
      }

      // Handle media
      if (get(props.item, 'video')) {
        setVideo(get(props.item, 'video'))
        setMediaType('video')
      } else if (get(props.item, 'photo')) {
        setPhoto(get(props.item, 'photo'))
        setMediaType('photo')
      }

      // Start date
      if (get(props.item, 'startDate')) {
        setStartDate(moment(get(props.item, 'startDate')).format('YYYY-MM-DD[T]HH:mm'))
      }

      // End date
      if (get(props.item, 'endDate')) {
        setEndDate(moment(get(props.item, 'endDate')).format('YYYY-MM-DD[T]HH:mm'))
      }

      // category
      if (get(props.item, 'category')) {
        setCategory(get(props.item, 'category'))
      }

      // price
      if (get(props.item, 'price')) {
        setPrice(get(props.item, 'price'))
      }

      // organization
      if (get(props.item, 'organization')) {
        setOrganization(get(props.item, 'organization'))
      }

      // name
      if (get(props.item, 'name')) {
        setName(get(props.item, 'name'))
      }

      // email
      if (get(props.item, 'email')) {
        setEmail(get(props.item, 'email'))
      }

      // link
      if (get(props.item, 'link')) {
        setLink(get(props.item, 'link'))
      }

      // phone
      if (get(props.item, 'phone')) {
        setPhone(get(props.item, 'phone'))
      }

      // address
      if (get(props.item, 'address')) {
        setAddress(get(props.item, 'address'))
      }

      // latitude
      if (get(props.item, 'latitude')) {
        setLatitude(get(props.item, 'latitude'))
      }

      // longitude
      if (get(props.item, 'longitude')) {
        setLongitude(get(props.item, 'longitude'))
      }
    }
  }, [props.item])

  const handleSave = () => {
    let newContentFi = null
    let newContentEn = null
    let newHtmlFi = null
    let newHtmlEn = null

    if (contentFi) {
      newHtmlFi = linkifyHtml(draftToHtml(convertToRaw(contentFi.getCurrentContent())))
      newContentFi = decode(striptags(newHtmlFi).trim())
    }

    if (contentEn) {
      newHtmlEn = linkifyHtml(draftToHtml(convertToRaw(contentEn.getCurrentContent())))
      newContentEn = decode(striptags(newHtmlEn).trim())
    }

    props.onSave({
      type: props.type,
      startDate: startDate ? moment(startDate).toISOString() : startDate,
      endDate: endDate ? moment(endDate).toISOString() : endDate,
      titleFi,
      titleEn,
      contentFi: newContentFi,
      contentEn: newContentEn,
      htmlFi: newHtmlFi,
      htmlEn: newHtmlEn,
      newPhoto,
      removePhoto,
      video,
      category,
      price,
      organization,
      name,
      email,
      link,
      phone,
      address,
      latitude: latitude ? parseFloat(latitude) : null,
      longitude: longitude ? parseFloat(longitude) : null
    })
  }

  const handleSetLatitude = (value) => {
    setLatitude(value.replace(',', '.').replace(/[^0-9.]/g, ''))
  }
  const handleSetLongitude = (value) => {
    setLongitude(value.replace(',', '.').replace(/[^0-9.]/g, ''))
  }

  const handleSetPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      setNewPhoto(file)
      setVideo(null)
      setMediaType('photo')
      setRemovePhoto(false)
    }
  }

  const handleRemovePhoto = () => {
    setPhoto(null)
    setVideo(null)
    setMediaType(null)
    setNewPhoto(null)
    setRemovePhoto(true)
  }

  const handleSetVideo = (newVideo) => {
    setVideo(newVideo)
    setPhoto(null)
    setNewPhoto(null)
    setRemovePhoto(true)
  }

  const handleDeleteVideo = () => {
    setMediaType(null)
    setVideo(null)
    setPhoto(null)
    setRemovePhoto(true)
  }

  // Check if current type has a specific field
  const hasField = (type) => config.fields.find(field => field === type)

  const hasAnyOfFields = (types) => {
    for (const type of types) {
      if (hasField(type)) return true
    }
    return false
  }

  const renderCategory = () => {
    if (!hasField('category')) return null
    return (
      <Select
        label={t('category')}
        options={get(config, 'options.category', [])}
        onChange={setCategory}
        value={category}
      />
    )
  }

  const renderDates = () => {
    return (
      <>
        {hasField('startDate') && (
          <Input
            type='datetime-local'
            label={t('start_date')}
            value={startDate}
            onChange={setStartDate}
          />
        )}
        {hasField('endDate') && (
          <Input
            type='datetime-local'
            label={t('end_date')}
            value={endDate}
            onChange={setEndDate}
          />
        )}
      </>
    )
  }

  const renderLangContent = () => {
    if (langTab === 'en') {
      return (
        <>
          {hasField('title') && <Input label={t('title_en')} value={titleEn} onChange={setTitleEn} />}
          {hasField('content') && (
            <EditorInput
              placeholder={t('placeholder_content')}
              value={contentEn}
              onChange={setContentEn}
            />
          )}
        </>
      )
    }
    return (
      <>
        {hasField('title') && <Input label={t('title_fi')} value={titleFi} onChange={setTitleFi} />}
        {hasField('content') && (
          <EditorInput
            placeholder={t('placeholder_content')}
            value={contentFi}
            onChange={setContentFi}
          />
        )}
      </>
    )
  }

  const getStatus = (locale) => {
    if (locale === 'fi') {
      if (hasField('title') && !titleFi) return 'red'
      if (hasField('content') && !contentFi) return 'red'
      return 'green'
    }
    if (locale === 'en') {
      if (hasField('title') && !titleEn) return 'red'
      if (hasField('content') && !contentEn) return 'red'
      return 'green'
    }
    return null
  }


  const renderMainContent = () => {
    if (!hasField('title') && !hasField('content')) return null
    return (
      <LangTabs
        currentLanguage={langTab}
        setLanguage={setLangTab}
        content={renderLangContent()}
        statusFi={getStatus('fi')}
        statusEn={getStatus('en')}
      />
    )
  }

  const renderMedia = () => {
    if (!hasField('photo') && !hasField('video')) return null
    return (
      <MediaInput
        video={video}
        setVideo={handleSetVideo}
        handleDeleteVideo={handleDeleteVideo}
        photo={photo}
        handleSetPhoto={handleSetPhoto}
        handleRemovePhoto={handleRemovePhoto}
        mediaType={mediaType}
        setMediaType={setMediaType}
      />
    )
  }



  const getCoordinates = () => {
    const API_URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_MAPS_API_KEY}`
    try {
      window.fetch(API_URL)
        .then((raw) => raw.json())
        .then((response) => {
          const newLat = get(response, 'results[0].geometry.location.lat')
          const newLon = get(response, 'results[0].geometry.location.lng')
          if (newLat && newLon) {
            handleSetLatitude(newLat.toString())
            handleSetLongitude(newLon.toString())
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  const renderMap = () => {
    if (!latitude || !longitude) {
      return (
        <div className={classes.mapsPlaceholder}>
          <p>{t('enter_coordinates_to_display_map')}</p>
        </div>
      )
    }

    return (
      <iframe
        src={`https://maps.google.com/maps?q=${latitude},${longitude}&hl=fi&z=10&output=embed`}
        width={MAP_WIDTH}
        height={MAP_HEIGHT}
        frameBorder={0}
        style={{ border: 0 }}
        aria-hidden='false'
        tabIndex={0}
        title='map'
      />
    )
  }

  const renderLocation = () => {
    return (
      <>
        <div className={classes.coordsRow}>
          <div className={classes.column}>
            <Input
              label={t('latitude')}
              value={latitude}
              onChange={handleSetLatitude}
            />
          </div>
          <div className={classes.spacer} />
          <div className={classes.column}>
            <Input
              label={t('longitude')}
              value={longitude}
              onChange={handleSetLongitude}
            />
          </div>
          <Button
            buttonStyle={classes.coordsButton}
            text={t('get_coordinates')}
            onClick={getCoordinates}
            small
            secondary
          />
        </div>
        {renderMap()}
      </>
    )
  }

  const renderAdditionalFields = () => {
    if (
      !hasAnyOfFields([
        'organization',
        'firstName',
        'lastName',
        'email',
        'link',
        'phone',
        'address',
        'latitude',
        'longitude'
      ])
    ) {
      return null
    }

    return (
      <>
        <br />
        <Title title={t('additional_info')} type='subtitle' />
        {hasField('link') && (
          <Input
            label={t('url')}
            value={link}
            onChange={setLink}
          />
        )}
        {hasField('price') && (
          <Input
            label={t('price')}
            value={price}
            onChange={setPrice}
          />
        )}
        {hasField('organization') && (
          <Input
            label={t('organization')}
            value={organization}
            onChange={setOrganization}
          />
        )}
        {hasField('name') && (
          <Input
            label={t('name')}
            value={name}
            onChange={setName}
          />
        )}
        {hasField('email') && (
          <Input
            label={t('email')}
            value={email}
            onChange={setEmail}
          />
        )}
        {hasField('phone') && (
          <Input
            label={t('phone')}
            value={phone}
            onChange={setPhone}
          />
        )}
        {hasField('address') && (
          <Input
            label={t('address')}
            value={address}
            onChange={setAddress}
          />
        )}
        {(hasField('latitude') && hasField('longitude')) && renderLocation()}
      </>
    )
  }

  const renderContent = () => {
    return (
      <div className={classes.container}>
        {renderCategory()}
        {renderDates()}
        {renderMainContent()}
        {renderMedia()}
        {renderAdditionalFields()}
      </div>
    )
  }

  return (
    <PageContainer>
      <PageContent disabled={props.mode === 'edit' && !get(props.item, 'active')}>
        {renderContent()}
      </PageContent>
      <PageActions>
        <div>
          <Button
            text={t('save')}
            onClick={handleSave}
            margin
            fullWidth
          />
          {renderDelete()}
        </div>
      </PageActions>
      <Dialog
        open={alertVisible}
        handleClose={closeAlert}
        handleOk={toggleDelete}
        title={get(props.item, 'active') ? t('delete_item_title') : t('activate_item_title')}
        description={get(props.item, 'active') ? t('delete_item_description') : t('activate_item_description')}
      />
    </PageContainer>
  )
}

export default DynamicItemForm
