import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { decode } from 'html-entities'
import * as striptags from 'striptags'
import linkifyHtml from 'linkify-html'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Title, Button, LangTabs, Input, PageContent, PageContainer, PageActions, EditorInput } from '../../../Components'
import { Colors, Fonts } from '../../../Utils/theme'
import MediaInput from './MediaInput'

const useStyles = makeStyles((theme) => ({
  text: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.125rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '1.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  boldText: {
    fontWeight: 700
  },
  verticalSpacer: {
    height: 30
  },
  publicationContainer: {
    padding: '1rem',
    backgroundColor: Colors.cardBackgroundColor,
    border: `1px solid ${Colors.lightBorder}`,
    borderRadius: 5,
    marginBottom: '1rem'
  },
  videoLinkRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  addButtonCompact: {
    marginLeft: '.5rem',
    width: 130
  },
  addButtonTextCompact: {
    fontSize: '0.875rem'
  },
  mediaRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

const CreateNotification = (props) => {
  const classes = useStyles()
  const { notificationStore } = useStore()

  useEffect(() => {
    if (notificationStore.createOk) {
      if (props.type === 'coupon') {
        props.history.push('/coupons?tab=3')
      } else {
        props.history.push('/notifications')
      }
    }
  }, [notificationStore.createOk])

  const [langTab, setLangTab] = useState('fi')

  const [titleFi, setTitleFi] = useState(null)
  const [titleEn, setTitleEn] = useState(null)
  const [contentFi, setContentFi] = useState(null)
  const [contentEn, setContentEn] = useState(null)

  const [photo, setPhoto] = useState(null)
  const [newPhoto, setNewPhoto] = useState(null)
  const [video, setVideo] = useState(null)

  const [publication, setPublication] = useState('specific_time')
  const handleSetPublication = (evt) => setPublication(evt.target.value)
  const [publishDateTime, setPublishDateTime] = useState(moment().add(90, 'minutes').minutes(0).format('YYYY-MM-DD[T]HH:mm'))

  const [submitted, setSubmitted] = useState(false)

  const [mediaType, setMediaType] = useState(null)

  const { t } = useTranslation()

  const handleSetPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      setNewPhoto(file)
      setVideo(null)
      setMediaType('photo')
    }
  }

  const handleRemovePhoto = () => {
    setPhoto(null)
    setVideo(null)
    setMediaType(null)
    setNewPhoto(null)
  }

  const createNotification = () => {
    setSubmitted(true)

    let newContentFi = null
    let newContentEn = null
    let newHtmlFi = null
    let newHtmlEn = null

    if (contentFi) {
      newHtmlFi = linkifyHtml(draftToHtml(convertToRaw(contentFi.getCurrentContent())))
      newContentFi = decode(striptags(newHtmlFi).trim())
    }

    if (contentEn) {
      newHtmlEn = linkifyHtml(draftToHtml(convertToRaw(contentEn.getCurrentContent())))
      newContentEn = decode(striptags(newHtmlEn).trim())
    }

    notificationStore.createNotification({
      type: props.type || 'general', // For coupons type is passed as prop
      titleFi,
      titleEn,
      contentFi: newContentFi,
      contentEn: newContentEn,
      htmlFi: newHtmlFi,
      htmlEn: newHtmlEn,
      newPhoto: newPhoto,
      video,
      publishedAt: publication === 'specific_time' ? moment(publishDateTime).seconds(0).milliseconds(0).toISOString() : moment().add(3, 'minutes').seconds(0).milliseconds(0).toISOString()
    })
  }

  const validationMessage = (field) => {
    if (submitted && !field) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
    return {}
  }

  const handleDeleteVideo = () => {
    setMediaType(null)
    setVideo(null)
    setPhoto(null)
  }

  const renderLangContent = () => {
    if (langTab === 'en') {
      return (
        <>
          <Input
            label={t('title_en')}
            value={titleEn}
            onChange={setTitleEn}
            {...validationMessage(titleEn)}
          />
          <EditorInput
            placeholder={t('placeholder_content')}
            value={contentEn}
            onChange={setContentEn}
          />
        </>
      )
    }
    return (
      <>
        <Input
          label={t('title_fi')}
          value={titleFi}
          onChange={setTitleFi}
          {...validationMessage(titleFi)}
        />
        <EditorInput
          placeholder={t('placeholder_content')}
          value={contentFi}
          onChange={setContentFi}
        />
      </>
    )
  }

  const getPollStatus = (lang) => {
    if (lang === 'fi') {
      if (titleFi && contentFi) return 'green'
      if (titleFi || contentFi) return 'yellow'
      return 'red'
    } else {
      if (titleEn && contentEn) return 'green'
      if (titleEn || contentEn) return 'yellow'
      return 'red'
    }
  }

  const renderContent = () => {
    return (
      <>
        <Title title={t('notification_details')} description={t('notification_details_description')} />
        <LangTabs
          currentLanguage={langTab}
          setLanguage={setLangTab}
          content={renderLangContent()}
          statusFi={getPollStatus('fi')}
          statusEn={getPollStatus('en')}
        />
        <MediaInput
          video={video}
          setVideo={setVideo}
          handleDeleteVideo={handleDeleteVideo}
          photo={photo}
          handleSetPhoto={handleSetPhoto}
          handleRemovePhoto={handleRemovePhoto}
          mediaType={mediaType}
          setMediaType={setMediaType}
        />
      </>
    )
  }

  return (
    <>
      <PageHeader
        title={t('create_notification')}
      />
      <PageContainer>
        <PageContent>
          {renderContent()}
        </PageContent>
        <PageActions>
          <div>
            <p className={[classes.text, classes.boldText].join(' ')}>
              {t('publication')}
            </p>
            <div className={classes.publicationContainer}>
              <FormControl component='fieldset'>
                <RadioGroup name='publication' value={publication} onChange={handleSetPublication}>
                  <FormControlLabel value='immediately' control={<Radio />} label={t('immediately')} />
                  <FormControlLabel value='specific_time' control={<Radio />} label={t('specific_time')} />
                </RadioGroup>
              </FormControl>
              <div className={classes.verticalSpacer} />
              {publication !== 'specific_time' ? null : (
                <Input type='datetime-local' label={t('notificaiton_send_time')} value={publishDateTime} onChange={setPublishDateTime} />
              )}
            </div>
            <Button
              text={t('save')}
              onClick={createNotification}
              margin
              fullWidth
            />
          </div>
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(CreateNotification)
