import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { LangTabs, Select, Input } from '../../Components'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 800
  },
  createLinkContainer: {
    textAlign: 'right',
    maxWidth: 700,
    marginBottom: '-.75rem',
    paddingRight: '.25rem',
    paddingBottom: '1rem'
  }
}))

const CouponForm = (props) => {
  const [langTab, setLangTab] = useState('fi')

  const classes = useStyles()
  const { t } = useTranslation()

  const getOrganizationOptions = () => props.organizations.map(org => ({
    value: org.id,
    label: org.name
  }))

  const getCategoryOptions = () => props.categories.map(cat => ({
    value: cat.id,
    label: cat.titleFi
  }))

  const getUseTimesOptions = () => [
    { value: 0, label: t('no_limit') },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' }
  ]

  const getStatus = (lang) => {
    if (lang === 'en') {
      if (!props.titleEn && !props.contentEn) return 'red'
      if (!props.titleEn || !props.contentEn) return 'yellow'
    } else {
      if (!props.titleFi && !props.contentFi) return 'red'
      if (!props.titleFi || !props.contentFi) return 'yellow'
    }
    return 'green'
  }

  const validationMessage = (field) => {
    if (props.submitted && !field) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
    return {}
  }

  const renderCreateCategoryLink = () => {
    if (props.mode !== 'create') return null

    // Check if user is coupon organization user
    const currentUser = props.user
    if (currentUser && currentUser.accessRights && currentUser.accessRights.includes('coupon_organization_user')) {
      return null
    }

    return (
      <div className={classes.createLinkContainer}>
        <Link to='/coupons/add-category'>
          {t('create_category')}
        </Link>
      </div>
    )
  }

  const renderCreateOrganizationLink = () => {
    if (props.mode !== 'create') return null

    // Check if user is coupon organization user
    const currentUser = props.user
    if (currentUser && currentUser.accessRights && currentUser.accessRights.includes('coupon_organization_user')) {
      return null
    }

    return (
      <div className={classes.createLinkContainer}>
        <Link to='/coupons/add-organization'>
          {t('create_organization')}
        </Link>
      </div>
    )
  }

  const renderLangContent = () => {
    if (langTab === 'en') {
      return (
        <>
          <Input
            label={t('title_en')}
            value={props.titleEn}
            onChange={props.setTitleEn}
            {...validationMessage(props.titleEn)}
          />
          <Input
            label={t('content_en')}
            value={props.contentEn}
            onChange={props.setContentEn}
            multiline
            {...validationMessage(props.contentEn)}
          />
        </>
      )
    }
    return (
      <>
        <Input
          label={t('title_fi')}
          value={props.titleFi}
          onChange={props.setTitleFi}
          {...validationMessage(props.titleFi)}
        />
        <Input
          label={t('content_fi')}
          value={props.contentFi}
          onChange={props.setContentFi}
          multiline
          {...validationMessage(props.contentFi)}
        />
      </>
    )
  }

  return (
    <div className={classes.container}>
      {renderCreateCategoryLink()}
      <Select
        label={t('category')}
        options={getCategoryOptions()}
        onChange={props.setCategoryId}
        value={props.categoryId}
        {...validationMessage(props.categoryId)}
      />
      {renderCreateOrganizationLink()}
      <Select
        label={t('organization')}
        options={getOrganizationOptions()}
        onChange={props.setOrganizationId}
        value={props.organizationId}
        {...validationMessage(props.organizationId)}
      />
      <Select
        label={t('use_times')}
        options={getUseTimesOptions()}
        onChange={props.setUseTimes}
        value={props.useTimes}
      />
      <Input
        type='date'
        label={t('valid_from')}
        value={props.validFrom}
        onChange={props.setValidFrom}
        {...validationMessage(props.validFrom)}
      />
      <Input
        type='date'
        label={t('valid_until')}
        value={props.validUntil}
        onChange={props.setValidUntil}
        {...validationMessage(props.validUntil)}
      />
      <LangTabs
        currentLanguage={langTab}
        setLanguage={setLangTab}
        content={renderLangContent()}
        statusFi={getStatus('fi')}
        statusEn={getStatus('en')}
      />
    </div>
  )
}

export default CouponForm
