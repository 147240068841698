import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import omit from 'lodash/omit'

export default class NotificationStore {
  rootStore
  notifications = []
  notification = null

  loading = null
  createOk = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async getNotifications() {
    this.loading = true
    const response: any = await Api.getNotifications()
    this.loading = false
    if (response.ok) {
      this.notifications = response.data
    }
  }

  async getNotification(id) {
    this.loading = true
    const response: any = await Api.getNotification(id)
    this.loading = false
    if (response.ok) {
      this.notification = response.data
    }
  }

  async updateNotification(id, notification) {
    this.loading = true
    const notificationData = omit(notification, ['newPhoto', 'removePhoto'])

    // Upload photo
    if (notification.newPhoto && !notification.removePhoto) {
      const photoResponse: any = await Api.uploadMedia(notification.newPhoto)
      if (photoResponse.ok) {
        notificationData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    } else if (notification.removePhoto) {
      // Remove photo
      notificationData.photo = null
    }

    const response: any = await Api.updateNotification(id, notificationData)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
    if (response.ok) {
      this.notification = response.data
    }
  }

  async createNotification(notification) {
    this.createOk = false
    this.loading = true

    const notificationData = omit(notification, ['newPhoto', 'removePhoto'])

    // Upload photo
    if (notification.newPhoto) {
      const photoResponse: any = await Api.uploadMedia(notification.newPhoto)
      if (photoResponse.ok) {
        notificationData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    }

    const response: any = await Api.createNotification(notificationData)

    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'notification_created')
    if (response.ok) {
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }
}
