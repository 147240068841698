import i18n from '../Localization'
import users from '../Assets/Icons/user.svg'
import analytics from '../Assets/Icons/analytics.svg'
import settings from '../Assets/Icons/settings.svg'
import notifications from '../Assets/Icons/notifications.svg'
import coupon from '../Assets/Icons/coupon.svg'
import feedback from '../Assets/Icons/feedback.svg'
import poll from '../Assets/Icons/poll.svg'
import item from '../Assets/Icons/list.svg'

export const GOOGLE_MAPS_API_KEY = 'AIzaSyD5TLAPZKSniAV9txUZI5cSMhbyT4ovMc4'

export const CITY_LOCATION = {
  latitude: 62.32646416576586,
  longitude: 27.89566377712348,
  radius: 30000
}

export const ITEM_TYPES = [
  {
    enabled: true, // Toggle support on/off
    type: 'sight',
    plural: 'sights',
    locales: ['fi', 'en'],
    html: true,
    fields: [
      'title', // Localized field
      'content', // Localized field
      'photo',
      'link',
      'address',
      'latitude',
      'longitude'
    ]
  },
  {
    enabled: false, // Toggle support on/off
    type: 'event',
    plural: 'events',
    locales: ['fi', 'en'],
    options: {
      category: [
        {
          label: 'Musiikki',
          value: 'music',
        },
        {
          label: 'Teatteri',
          value: 'theatre'
        }
      ]
    },
    html: true,
    fields: [
      'startDate',
      'endDate',
      'category',
      'title', // Localized field
      'content', // Localized field
      'photo',
      'video',
      'price',
      'link',
      'organization',
      'name',
      'email',
      'phone',
      'address',
      'latitude',
      'longitude'
    ]
  }
].filter(it => !!it.enabled)

export enum Role {
  Admin = 'admin',
  User = 'user'
}
export const ROLES = ['admin', 'user']

export const ACCESS_RIGHTS = [
  'coupon',
  'notification',
  'poll',
  'influence',
  ITEM_TYPES.length ? 'item' : null,
  'analytics'
].filter(val => !!val)

export const AvailableRoutes = (user) => {
  if (!user || !user.role) return []
  const { role, accessRights } = user

  const accessCheck = (accessRight, data) => {
    if (accessRight === 'item' && !ITEM_TYPES.length) return null
    if (role === 'admin') return data

    // Handle coupon organization users
    if (accessRight === 'coupon' && accessRights && accessRights.includes('coupon_organization_user')) {
      return data
    }

    if (accessRights && accessRights.includes(accessRight)) {
      return data
    }
    return null
  }

  return [
    accessCheck('coupon', { url: '/coupons', icon: coupon, text: 'coupons' }),
    accessCheck('poll', { url: '/polls', icon: poll, text: 'polls' }),
    accessCheck('notification', { url: '/notifications', icon: notifications, text: 'notifications' }),
    accessCheck('influence', { url: '/feedbacks', icon: feedback, text: 'feedbacks' }),
    accessCheck('item', { url: '/items', icon: item, text: ITEM_TYPES.length === 1 ? ITEM_TYPES[0].plural : 'items' }),
    accessCheck('analytics', { url: '/analytics', icon: analytics, text: 'analytics' }),
    accessCheck('admin', { url: '/users', icon: users, text: 'users' }),
    { url: '/settings', icon: settings, text: 'settings' }
  ].filter(item => !!item)
}

export const UserSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' }
]

export const CouponSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' },
  { id: 3, label: i18n.t('sort_by_popularity'), value: 'popularity' }
]

export const OrganizationSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' }
]

export enum ViewMode {
  List = 'list',
  Grid = 'grid'
}

