import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import qs from 'qs'
import { withRouter, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Title, Button, Input, Select, PageContainer, PageContent, PageActions } from '../../../Components'
import { Colors, Fonts } from '../../../Utils/theme'
import { ROLES } from '../../../Constants'
import { formatDateTime } from '../../../Utils/dateTime'

enum Tabs {
  Profile = 'profile',
  Account = 'account',
  Organization = 'organization',
  Notifications = 'notifications',
  Security = 'security'
}

const useStyles = makeStyles((theme) => ({
  text: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.125rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '1.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  boldText: {
    fontWeight: 700
  },
  line: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.lightBorder,
    marginBottom: '2rem'
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 1055,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  spacer: {
    width: '3rem',
    height: '1rem',
    [theme.breakpoints.down('md')]: {
      height: 0
    }
  },
  infoContainer: {
    paddingRight: '3rem',
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  accountContainer: {
    padding: '1rem 0'
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  address: {
    width: 700
  },
  zip: {
    width: 168,
    marginRight: '2rem'
  },
  city: {
    width: 500
  },
  progress: {
    position: 'absolute',
    top: '3rem',
    right: '3rem',
    [theme.breakpoints.down('md')]: {
      top: '2rem',
      right: '2rem'
    }
  },
  smallButton: {
    width: '12rem',
    margin: 0,
    marginBottom: '3rem',
    [theme.breakpoints.down('md')]: {
      width: '10rem',
      marginBottom: '2rem'
    }
  }
}))

const Settings = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionStore, appStore } = useStore()

  let initialTab = parseInt(qs.parse(props.location.search, { ignoreQueryPrefix: true }).tab || '0', 10)
  const history = useHistory()

  useEffect(() => {
    if (appStore.notification && appStore.notification.type === 'success') {
      // Clear fields after success
      setCurrentPassword('')
      setNewPassword('')
      setNewPasswordAgain('')
    }
  }, [appStore.notificationUpdatedAt])

  // Common
  const [tab, setTab] = useState(initialTab)

  // User
  const [firstName, setFirstName] = useState(get(sessionStore.user, 'firstName', ''))
  const [lastName, setLastName] = useState(get(sessionStore.user, 'lastName', ''))
  const [email, setEmail] = useState(get(sessionStore.user, 'email', ''))
  const [role, setRole] = useState(get(sessionStore.user, 'role', ''))
  const [userSubmitted, setUserSubmitted] = useState(false)

  // Security
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordAgain, setNewPasswordAgain] = useState('')

  const getTabOptions = () => [
    { id: Tabs.Profile, label: t('own_profile'), description: t('own_profile_description') },
    { id: Tabs.Security, label: t('security'), description: t('security_description') }
  ]
  const tabOptions = getTabOptions()

  const getRoles = () => ROLES.map((role, index) => ({ id: index + 1, label: t(role), value: role }))

  const selectTab = (evt, value) => {
    const params = new URLSearchParams()
    params.append('tab', value)

    history.push({search: params.toString()})
    setTab(value)
  }

  const updateProfile = () => {
    const currentEmail = get(sessionStore.user, 'email')
    sessionStore.updateMe({ firstName, lastName, email })
    if (email && currentEmail && email !== currentEmail) {
      // Create email change request if email was changed
      // sessionStore.createEmailChange(email)
    }
    setUserSubmitted(true)
  }

  const changePassword = () => {
    sessionStore.changePassword(currentPassword, newPassword, newPasswordAgain)
  }

  const emailValidation = () => {
    if (userSubmitted && !/\S+@\S+\.\S+/.test(email)) {
      return {
        error: true,
        helperText: t('invalid_email')
      }
    }
    return {}
  }

  const renderContent = () => {
    const tabId = tabOptions[tab].id
    if (tabId === Tabs.Account) {
      return (
        <div className={classes.accountContainer}>
          <Select options={getRoles()} label={t('role')} value={role} onChange={setRole} disabled />
        </div>
      )
    }

    if (tabId === Tabs.Security) {
      return (
        <>
          <Title title={t('change_password')} type='subtitle' />
          <Input
            label={t('current_password')}
            value={currentPassword}
            onChange={setCurrentPassword}
            type='password'
          />
          <Input
            label={t('new_password')}
            value={newPassword}
            onChange={setNewPassword}
            type='password'
          />
          <Input
            label={t('new_password_again')}
            value={newPasswordAgain}
            onChange={setNewPasswordAgain}
            type='password'
          />
          <Button
            text={t('change_password')}
            onClick={changePassword}
            buttonStyle={classes.smallButton}
          />
        </>
      )
    }

    return (
      <>
          <Input label={t('first_name')} value={firstName} onChange={setFirstName} />
          <Input label={t('last_name')} value={lastName} onChange={setLastName} />
          <Input label={t('email')} value={email} onChange={setEmail} {...emailValidation()} />
      </>
    )
  }

  const renderActions = () => {
    const tabId = tabOptions[tab].id

    if (tabId === Tabs.Profile) {
      return (
        <>
          <div>
            <p className={classes.text}>
              <span className={classes.boldText}>{t('last_modified')}: </span>
              {formatDateTime(get(sessionStore, 'user.updatedAt'))}
            </p>
            <Button
              text={t('update_own_profile')}
              onClick={updateProfile}
              margin
              fullWidth
            />
          </div>
        </>
      )
    }

    if (tabId === Tabs.Account) {
      return (
        <>
          <div>
            <p className={classes.text}>
              <span className={classes.boldText}>{t('last_modified')}: </span>
              {formatDateTime(get(sessionStore, 'user.updatedAt'))}
            </p>
          </div>
        </>
      )
    }

    return null
  }

  return (
    <>
      <PageHeader
        title={t('settings')}
        tabs={tabOptions}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <PageContainer>
        <PageContent>
          <Title title={tabOptions[tab].label} description={tabOptions[tab].description} />
          {renderContent()}
        </PageContent>
        <PageActions>
          {renderActions()}
        </PageActions>
      </PageContainer>
    </>
  )
}

export default withRouter(observer(Settings))
