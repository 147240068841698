import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import orderBy from 'lodash/orderBy'
import qs from 'qs'
import { useStore } from '../../../Models/RootStore'
import { withRouter } from 'react-router-dom'
import { PageHeader, ItemTable, DataActions, PageContainer, PageContent, ItemTypeGrid } from '../../../Components'
import { UserSortOptions, ITEM_TYPES } from '../../../Constants'

function Items(props) {
  const { t } = useTranslation()
  const { itemStore } = useStore()

  useEffect(() => {
    itemStore.getItems()
  }, [])

  const type = qs.parse(props.location.search, { ignoreQueryPrefix: true }).type || (ITEM_TYPES.length === 1 ? ITEM_TYPES[0].type : null)

  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value)
  const [filterValue, setFilterValue] = useState('active')

  const config = ITEM_TYPES.find(item => item.type === type)

  const handleSetType = (value) => {
    const params = new URLSearchParams()
    params.append('type', value)

    props.history.push({search: params.toString()})
  }

  const toCreate = () => {
    props.history.push(`/items/add?type=${type}`)
  }

  /* Items */
  const getItems = () => {
    let items = itemStore.items

    // Filter by type
    items = items.filter(item => item.type === type)

    if (searchValue) {
      items = items.filter((item) => {
        const { titleFi = '', contentFi = '', titleEn = '', contentEn = '' } = item
        const targets = [titleFi, titleEn, contentFi, contentEn]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }
    if (sortValue === 'created_at') {
      items = orderBy(items, 'createdAt', 'desc')
    } else {
      items = orderBy(items, 'titleFi', 'asc')
    }
    // Filter
    items = items.filter(item => {
      if (filterValue === 'active') return item.active
      else if (filterValue === 'deleted') return !item.active
      return true
    })

    // Sort deleted last
    items = orderBy(items, 'active', 'desc')

    return items
  }

  const getFilterOptions = () => {
    return [
      { label: t('view_active'), value: 'active' },
      { label: t('view_all'), value: 'all' },
      { label: t('view_deleted'), value: 'deleted' },
    ]
  }

  const renderTable = () => {
    return <ItemTable items={getItems()} />
  }

  const renderContent = () => {
    if (type) {
      return (
        <>
          <DataActions
            searchPlaceholder={t('search_by_name')}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            sortOptions={UserSortOptions}
            sortValue={sortValue}
            onSortChange={setSortValue}
            filterOptions={getFilterOptions()}
            filterValue={filterValue}
            onFilterChange={setFilterValue}
          />
          {renderTable()}
        </>
      )
    } else {
      return (
        <ItemTypeGrid
          types={ITEM_TYPES}
          type={type}
          setType={handleSetType}
          items={itemStore.items}
        />
      )
    }

  }

  return (
    <>
      <PageHeader
        title={type ? t(config.plural) : t('items')}
        onCreateClick={type ? toCreate : null}
      />
      <PageContainer>
        <PageContent>
          {renderContent()}
        </PageContent>
      </PageContainer>
    </>
  )
}

export default withRouter(observer(Items))
