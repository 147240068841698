import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import qs from 'qs'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, DynamicItemForm } from '../../../Components'

const CreateItem = (props) => {
  const { itemStore } = useStore()

  const type = qs.parse(props.location.search, { ignoreQueryPrefix: true }).type || null

  useEffect(() => {
    if (itemStore.createOk) {
      props.history.push(`/items?type=${type}`)
    }
  }, [itemStore.createOk])

  const { t } = useTranslation()

  const createItem = (item) => {
    itemStore.createItem(item)
  }

  return (
    <>
      <PageHeader
        title={`${t('create')} ${t(type).toLowerCase()}`}
      />
       <DynamicItemForm
        onSave={createItem}
        type={type}
        mode='create'
      />
    </>
  )
}

export default withRouter(observer(CreateItem))
