import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import orderBy from 'lodash/orderBy'
import qs from 'qs'
import capitalize from 'lodash/capitalize'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from '../../../Models/RootStore'
import { withRouter, useHistory } from 'react-router-dom'
import { PageHeader, UserTable, UserInviteTable, DataActions, PageContainer, PageContent } from '../../../Components'
import { UserSortOptions } from '../../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '3rem',
    [theme.breakpoints.down('xl')]: {
      padding: '2.5rem 3rem'
    },
    [theme.breakpoints.down('md')]: {
      padding: '2rem'
    }
  }
}))

function Users(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { userStore } = useStore()

  let initialTab = parseInt(qs.parse(props.location.search, { ignoreQueryPrefix: true }).tab || '0', 10)
  const history = useHistory()

  useEffect(() => {
    userStore.getMyOrganizationUsers()
    userStore.getUserInvites()
  }, [])

  const inviteUser = (email, role) => {
    userStore.inviteUser({ email, role })
  }

  const [tab, setTab] = useState(initialTab)

  const selectTab = (evt, value) => {
    const params = new URLSearchParams()
    params.append('tab', value)

    history.push({search: params.toString()})
    setTab(value)
  }

  const getTabs = () => [
    { id: 1, label: t('users') },
    { id: 2, label: t('invites') }
  ]
  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value)
  const [filterValue, setFilterValue] = useState('active')

  const toCreateUser = () => props.history.push('/users/invite')
  const toUser = (id) => props.history.push(`/users/${id}`)

  const getFilterOptions = () => {
    if (tab === 0) {
      return [
        { label: t('view_active'), value: 'active' },
        { label: t('view_all'), value: 'all' },
        { label: t('view_deleted'), value: 'deleted' }
      ]
    }
    return null
  }

  const extractNameFromEmail = (email) => {
    const namePart = email.split('@')[0]
    // Handle dots and dashes
    return namePart.split('.').map(str => capitalize(str)).join(' ').split('-').map(str => capitalize(str)).join('-')
  }

  const getUsers = () => {
    let users
    if (tab === 0) users = userStore.users

    // Invites
    if (tab === 1) users = userStore.userInvites.map(user => ({
      ...user,
      firstName: extractNameFromEmail(user.email)
    }))

    if (searchValue) {
      users = users.filter((user) => {
        const { firstName = '', lastName = '', role = '', email = '' } = user
        const targets = [firstName, lastName, t(role), email]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }

    if (tab === 3 && sortValue === 'created_at') {
      users = orderBy(users, 'expiresAt', 'desc')
    }
    else if (sortValue === 'created_at') {
      users = orderBy(users, 'createdAt', 'desc')
    }
    else if (tab === 3) {
      users = orderBy(users, 'email', 'asc')
    }
    else {
      users = orderBy(users, 'lastName', 'asc')
    }

    // Filter
    if (tab === 0) {
      users = users.filter(item => {
        if (filterValue === 'active') return item.active
        else if (filterValue === 'deleted') return !item.active
        return true
      })
    }

    return users
  }

  const renderTable = () => {
    if (tab === 1) {
      return <UserInviteTable users={getUsers()} inviteUser={inviteUser} />
    }
    return <UserTable users={getUsers()} />
  }

  return (
    <div className={classes.root}>
      <PageHeader
        title={t('users')}
        tabs={getTabs()}
        currentTab={tab}
        onTabChange={selectTab}
        onCreateClick={toCreateUser}
      />
      <PageContainer>
        <PageContent>
          <DataActions
            searchPlaceholder={t('search_by_name')}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            sortOptions={UserSortOptions}
            sortValue={sortValue}
            onSortChange={setSortValue}
            filterOptions={getFilterOptions()}
            filterValue={filterValue}
            onFilterChange={setFilterValue}
          />
            {renderTable()}
          </PageContent>
        </PageContainer>
      </div>
  )
}

export default withRouter(observer(Users))
