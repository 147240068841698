import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import * as striptags from 'striptags'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import linkifyHtml from 'linkify-html'
import { decode } from 'html-entities'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Title, Button, LangTabs, Input, PageActions, PageContent, PageContainer, Dialog, EditorInput } from '../../../Components'
import { Colors, Fonts } from '../../../Utils/theme'
import MediaInput from './MediaInput'

const useStyles = makeStyles((theme) => ({
  text: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.125rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '1.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  boldText: {
    fontWeight: 700
  },
  verticalSpacer: {
    height: 30
  },
  publicationContainer: {
    padding: '1rem',
    backgroundColor: Colors.cardBackgroundColor,
    border: `1px solid ${Colors.lightBorder}`,
    borderRadius: 5,
    marginBottom: '1rem'
  },
  line: {
    width: '100%',
    borderBottom: `1px solid ${Colors.lightBorder}`
  }
}))

const Notification = (props) => {
  const classes = useStyles()
  const { notificationStore } = useStore()
  const { notificationId }: any = useParams()

  useEffect(() => {
    notificationStore.getNotification(notificationId)
  }, [])

  useEffect(() => {
    if (notificationStore.notification) {
      setTitleFi(get(notificationStore.notification, 'titleFi', null))
      setTitleEn(get(notificationStore.notification, 'titleEn', null))

      const newContentFi = get(notificationStore.notification, 'htmlFi') || get(notificationStore.notification, 'contentFi') || ''
      if (newContentFi) {
        const { contentBlocks, entityMap } = htmlToDraft(newContentFi)
        setContentFi(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
      }

      const newContentEn = get(notificationStore.notification, 'htmlEn') || get(notificationStore.notification, 'contentEn') || ''
      if (newContentEn) {
        const { contentBlocks, entityMap } = htmlToDraft(newContentEn)
        setContentEn(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
      }

      // Handle media
      if (get(notificationStore.notification, 'video')) {
        setVideo(get(notificationStore.notification, 'video'))
        setMediaType('video')
      } else if (get(notificationStore.notification, 'photo')) {
        setPhoto(get(notificationStore.notification, 'photo'))
        setMediaType('photo')
      }

      setPublishDateTime(get(notificationStore.notification, 'publishedAt') ? moment(get(notificationStore.notification, 'publishedAt')).format('YYYY-MM-DD[T]HH:mm') : null)
    }
  }, [notificationStore.notification])

  const [langTab, setLangTab] = useState('fi')

  const [titleFi, setTitleFi] = useState(null)
  const [titleEn, setTitleEn] = useState(null)
  const [contentFi, setContentFi] = useState(null)
  const [contentEn, setContentEn] = useState(null)

  const [photo, setPhoto] = useState(null)
  const [newPhoto, setNewPhoto] = useState(null)
  const [removePhoto, setRemovePhoto] = useState(false)
  const [video, setVideo] = useState(null)
  const [mediaType, setMediaType] = useState(null)

  const [publishDateTime, setPublishDateTime] = useState(null)

  const [alertVisible, setAlertVisible] = useState(false)
  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)

  const { t } = useTranslation()

  const handleSetPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      setNewPhoto(file)
      setVideo(null)
      setMediaType('photo')
      setRemovePhoto(false)
    }
  }

  const handleRemovePhoto = () => {
    setPhoto(null)
    setVideo(null)
    setMediaType(null)
    setNewPhoto(null)
    setRemovePhoto(true)
  }

  const handleSetVideo = (newVideo) => {
    setVideo(newVideo)
    setPhoto(null)
    setNewPhoto(null)
    setRemovePhoto(true)
  }

  const updateNotification = () => {
    let newContentFi = null
    let newContentEn = null
    let newHtmlFi = null
    let newHtmlEn = null

    if (contentFi) {
      newHtmlFi = linkifyHtml(draftToHtml(convertToRaw(contentFi.getCurrentContent())))
      newContentFi = decode(striptags(newHtmlFi).trim())
    }

    if (contentEn) {
      newHtmlEn = linkifyHtml(draftToHtml(convertToRaw(contentEn.getCurrentContent())))
      newContentEn = decode(striptags(newHtmlEn).trim())
    }

    notificationStore.updateNotification(notificationId, {
      titleFi,
      titleEn,
      contentFi: newContentFi,
      contentEn: newContentEn,
      htmlFi: newHtmlFi,
      htmlEn: newHtmlEn,
      newPhoto,
      removePhoto,
      video,
      publishedAt: moment(publishDateTime).toISOString()
    })
  }

  const handleDeleteVideo = () => {
    setMediaType(null)
    setVideo(null)
    setPhoto(null)
    setRemovePhoto(true)
  }

  const renderLangContent = () => {
    if (langTab === 'en') {
      return (
        <>
          <Input label={t('title_en')} value={titleEn} onChange={setTitleEn} />
          <EditorInput
            placeholder={t('placeholder_content')}
            value={contentEn}
            onChange={setContentEn}
          />
        </>
      )
    }
    return (
      <>
        <Input label={t('title_fi')} value={titleFi} onChange={setTitleFi} />
        <EditorInput
          placeholder={t('placeholder_content')}
          value={contentFi}
          onChange={setContentFi}
        />
      </>
    )
  }

  const getPollStatus = (lang) => {
    if (lang === 'fi') {
      if (titleFi && contentFi) return 'green'
      if (titleFi || contentFi) return 'yellow'
      return 'red'
    } else {
      if (titleEn && contentEn) return 'green'
      if (titleEn || contentEn) return 'yellow'
      return 'red'
    }
  }

  const renderContent = () => {
    return (
      <>
        <Title title={t('notification_details')} description={t('notification_details_description')} />
        <LangTabs
          currentLanguage={langTab}
          setLanguage={setLangTab}
          content={renderLangContent()}
          statusFi={getPollStatus('fi')}
          statusEn={getPollStatus('en')}
        />
        <MediaInput
          video={video}
          setVideo={handleSetVideo}
          handleDeleteVideo={handleDeleteVideo}
          photo={photo}
          handleSetPhoto={handleSetPhoto}
          handleRemovePhoto={handleRemovePhoto}
          mediaType={mediaType}
          setMediaType={setMediaType}
        />
      </>
    )
  }

  const toggleDelete = () => {
    notificationStore.updateNotification(notificationId, {
      active: !notificationStore.notification.active
    })
    closeAlert()
  }

  const renderDelete = () => {
    if (notificationStore.notification) {
      return (
        <div>
          <div className={classes.line}></div>
          <Button
            text={notificationStore.notification.active ? t('delete_notification') : t('activate_notification')}
            onClick={openAlert}
            secondary
            margin
            fullWidth
          />
        </div>
      )
    }
    return null
  }

  const notiType = get(notificationStore.notification, 'type')
  if (props.type === 'coupon' && notiType && notiType !== 'coupon') {
    // Disable editing of general notification from coupon notifications
    return null
  } else if (props.type !== 'coupon' && notiType && notiType === 'coupon') {
    // Disable editing of coupon notifications from notifications
    return null
  }

  return (
    <>
      <PageHeader
        title={t('notification_details')}
      />
      <PageContainer>
        <PageContent disabled={!get(notificationStore.notification, 'active')}>
          {renderContent()}
        </PageContent>
        <PageActions>
          <div>
            <p className={[classes.text, classes.boldText].join(' ')}>
              {t('publication')}
            </p>
            <div className={classes.publicationContainer}>
              <div className={classes.verticalSpacer} />
              <Input type='datetime-local' label={t('notificaiton_send_time')} value={publishDateTime} onChange={setPublishDateTime} />
            </div>
            <Button
              text={t('save')}
              onClick={updateNotification}
              margin
              fullWidth
            />
            {renderDelete()}
          </div>
        </PageActions>
      </PageContainer>
      <Dialog
        open={alertVisible}
        handleClose={closeAlert}
        handleOk={toggleDelete}
        title={get(notificationStore.notification, 'active') ? t('delete_notification_title') : t('activate_notification_title')}
        description={get(notificationStore.notification, 'active') ? t('delete_notification_description') : t('activate_notification_description')}
      />
    </>
  )
}

export default observer(Notification)

