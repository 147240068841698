import React from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import Linkify from 'react-linkify'
import { Title } from '../../Components'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'
import { formatDateTime } from '../../Utils/dateTime'
import api from '../../Services/Api'

const MAP_HEIGHT = 420
const MAP_WIDTH = '100%'

const useStyles = makeStyles((theme) =>({
  container: {
    maxWidth: 800
  },
  title: {
    color: Colors.content
  },
  message: {
    color: Colors.content,
    fontSize: '1.125rem',
    border: `1px solid ${Colors.lightBorder}`,
    padding: '1rem .8rem .25rem',
    borderRadius: 3,
    marginBottom: '2rem'
  },
  date: {
    color: Colors.content,
    fontWeight: 700,
    marginBottom: '1rem',
    fontSize: '1.125rem'
  },
  image: {
    maxWidth: '100%',
    maxHeight: 400,
    resizeMode: 'contain'
  },
  detailRow: {
    marginBottom: '1rem',
    fontSize: '1.125rem',
    paddingBottom: '.25rem'
  },
  newLine: {
    marginBottom: '1rem'
  },
  fieldName: {
    opacity: 0.5,
    display: 'block',
    fontSize: '0.8rem',
    paddingBottom: '.25rem',
  },
  detailValue: {
    border: `1px solid ${Colors.lightBorder}`,
    padding: '.75rem .8rem',
    borderRadius: 3
  },
  mapContainer: {
    paddingBottom: '1.25rem'
  }
}))

function FeedbackInfo (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderTitles = () => {
    const { item } = props
    if (props.type === 'fault_report') {
      return (
        <div>
          <div className={classes.detailRow}>
            <span className={classes.fieldName}>
              {t('main_category')}
            </span>
            <div className={classes.detailValue}>
              {item.mainCategory || '-'}
            </div>
          </div>
          <div className={classes.detailRow}>
            <span className={classes.fieldName}>
              {t('sub_category')}
            </span>
            <div className={classes.detailValue}>
              {item.subCategory || '-'}
            </div>
          </div>
        </div>
      )
    } else {
      // City feedback

      return (
        <div>
          <div className={classes.detailRow}>
            <span className={classes.fieldName}>
              {t('title')}
            </span>
            <div className={classes.detailValue}>
              {item.title || '-'}
            </div>
          </div>
        </div>
      )
    }
  }

  const renderWithNewLines = (text) => {
    const componentDecorator = (href, text, key) => <a key={key} href={href} target='_blank' rel='noreferrer noopener'>{text}</a>
    return !text ? text : text.split('\n').map((line, index) => (
      <div key={index} className={classes.newLine}>
        <Linkify componentDecorator={componentDecorator}>{line}</Linkify>
      </div>
    ))
  }

  const renderImage = () => {
    // TODO: remove mediaId when migrations are ready
    const { mediaId, photo } = props.item
    if (photo || mediaId) {
      return (
        <>
          <Title title={t('attachment')} type='subtitle' />
          <img
            src={photo || api.getMediaUrl(props.item.mediaId)}
            className={classes.image}
            alt='attachment'
          />
        </>
      )
    }
  }

  const renderMap = () => {
    const latitude = get(props.item, 'location.coords.lat')
    const longitude = get(props.item, 'location.coords.lng')

    if (!latitude || !longitude) return null

    return (
      <div className={classes.mapContainer}>
        <Title title={t('location_on_map')} type='subtitle' />
        <iframe
          src={`https://maps.google.com/maps?q=${latitude},${longitude}&hl=fi&z=10&output=embed`}
          width={MAP_WIDTH}
          height={MAP_HEIGHT}
          frameBorder={0}
          style={{ border: 0 }}
          aria-hidden='false'
          tabIndex={0}
          title='map'
        />
      </div>
    )
  }

  const renderLocation = () => {
    const longName = get(props.item, 'location.longName') || ''
    if (longName) {
      return (
        <>
          <div className={classes.detailRow}>
            <span className={classes.fieldName}>
              {t('address')}
            </span>
            <div className={classes.detailValue}>
              {longName || '-'}
            </div>
          </div>
        </>
      )
    }
    return null
  }

  return (
    <div className={classes.container}>
      <div className={classes.detailRow}>
        <span className={classes.fieldName}>
          {t('sent_at')}
        </span>
        <div className={classes.detailValue}>
          {formatDateTime(props.item.createdAt)}
        </div>
      </div>
      <div className={classes.detailRow}>
        <span className={classes.fieldName}>
          {t('email')}
        </span>
        <div className={classes.detailValue}>
          {props.item.email || '-'}
        </div>
      </div>
      {renderTitles()}
      {renderLocation()}
      <span className={classes.fieldName}>
        {t('content')}
      </span>
      <div className={classes.message}>
        {renderWithNewLines(props.item.message)}
      </div>
      {renderMap()}
      {renderImage()}
    </div>
  )
}

export default FeedbackInfo
