import React, { useMemo, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { Button, Dialog } from '../../Components'
import closeIcon from '../../Assets/Icons/close.svg'
import { Colors } from '../../Utils/theme'

const baseStyle = {
  flex: 1,
  display: 'inline-block',
  position: 'relative',
  outline: 'none'
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'inline-block'
  },
  addButton: {
    height: 50,
    width: 130
  },
  addButtonText: {
    fontSize: '0.875rem'
  },
  photo: {
    width: 450,
    height: 250,
    objectFit: 'cover'
  },
  removePhoto: {
    width: '2rem',
    height: '2rem',
    position: 'absolute',
    top: '.85rem',
    right: '.85rem',
    backgroundColor: Colors.black,
    '&:hover': {
      backgroundColor: Colors.black
    }
  },
  closeIcon: {
    maxHeight: '1.25rem',
    maxWidth: '1.25rem'
  }
}))

export default function ImageUpload(props) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { t } = useTranslation()

  const [alertVisible, setAlertVisible] = useState(false)

  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)

  const removePhoto = () => {
    setAlertVisible(false)
    props.removePhoto()
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*',
    multiple: false,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    onDrop: props.onFileUpload
  })
  const style = useMemo(() => ({
    ...(
      baseStyle
    )
  }), [])

  const renderPhoto = () => {
    if (props.photo) {
      return (
        <img
          src={props.photo}
          className={classes.photo}
          alt='profile'
        />
      )
    }
  }

  const renderRemovePhoto = () => {
    if (props.photo && props.removePhoto) {
      return (
        <>
          <IconButton
            onClick={openAlert}
            className={classes.removePhoto}
            disabled={props.disabled}
          >
            <img
              src={closeIcon}
              className={classes.closeIcon}
              alt='icon'
            />
          </IconButton>
          <Dialog
            open={alertVisible}
            handleClose={closeAlert}
            handleOk={removePhoto}
            title={t('delete_photo')}
            description={t('delete_photo_description')}
          />
        </>
      )
    }
    return null
  }

  const renderRoot = () => {
    if (props.photo) {
      return (
        <div className={classes.root}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {renderPhoto()}
          </div>
          {renderRemovePhoto()}
        </div>
      )
    } else {
      const rootProps = getRootProps({ style })
      rootProps.style = undefined
      return (
        <div {...rootProps}>
          <input {...getInputProps()} />
          <Button
            text={t('add_photo')}
            onClick={open}
            disabled={props.disabled}
            buttonStyle={classes.addButton}
            buttonTextStyle={classes.addButtonText}
          />
        </div>
      )
    }
  }

  return renderRoot()
}
