import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import omit from 'lodash/omit'

export default class ItemStore {
  rootStore
  items = []
  item = null

  loading = null
  createOk = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async getItems() {
    this.loading = true
    const response: any = await Api.getItems()
    this.loading = false
    if (response.ok) {
      this.items = response.data
    }
  }

  async getItem(id) {
    this.loading = true
    const response: any = await Api.getItem(id)
    this.loading = false
    if (response.ok) {
      this.item = response.data
    }
  }

  async updateItem(id, item) {
    this.loading = true
    const itemData = omit(item, ['newPhoto', 'removePhoto'])

    // Upload photo
    if (item.newPhoto && !item.removePhoto) {
      const photoResponse: any = await Api.uploadMedia(item.newPhoto)
      if (photoResponse.ok) {
        itemData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    } else if (item.removePhoto) {
      // Remove photo
      itemData.photo = null
    }

    const response: any = await Api.updateItem(id, itemData)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
    if (response.ok) {
      this.item = response.data
    }
  }

  async createItem(item) {
    this.createOk = false
    this.loading = true

    // Detect if all fields are empty and show error
    let allEmpty = true
    for (const property in item) {
      if (property !== 'type' && item[property]) {
        allEmpty = false
      }
    }
    if (allEmpty) {
      this.rootStore.appStore.setErrorOrSuccess({ ok: false }, 'fill_required_fields', false)
      return
    }

    const itemData = omit(item, ['newPhoto', 'removePhoto'])

    // Upload photo
    if (item.newPhoto) {
      const photoResponse: any = await Api.uploadMedia(item.newPhoto)
      if (photoResponse.ok) {
        itemData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    }

    const response: any = await Api.createItem(itemData)

    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'item_created')
    if (response.ok) {
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }
}
